import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../Components/Utils/CustomSelect";
import { useDispatch } from "react-redux";
import Input from "../../Components/Utils/Input";
import {
  useGetCoursesQuery,
  useGetCollegesQuery,
  useGetCertificateQuery,
  useLazyGetDegreeTypesByCourseQuery,
  useLazyGetDegreeModesByCourseQuery,
  useGetGradesQuery,
} from "./../../store/slices/apis/masterApi";
import AssameseKeyboard from "../../Components/Utils/AssameseKeyboard.jsx";
import AssameseInput from "../../Components/Utils/AssameseInput";
import {
  useAddApplicationMutation,
  useGetApplicationQuery,
  useUpdateApplicationMutation,
  useLazyGetDataByRegNoQuery,
} from "./../../store/slices/apis/applicationApi";
import { toast } from "react-toastify";
import Loader from "../../Components/Utils/Loader";
import useDebounce from "../../hooks/useDebounce";
import axios from "axios";
import SearchInput from "../../Components/Utils/SearchInput";
import SearchableDropdown from "../../Components/Utils/SearchableDropdown";
export const CertificateCreateStep1 = ({ data, certificate_id, setTab }) => {
  // console.log("CertificateCreateStep1", data?.step, data?.step > 1);

  const [isLoading, setLoading] = useState(false);
  const [autoPopulate, setAutoPopulate] = useState(false);
  const [regNoSuggestions, setRegNoSuggestions] = useState([]);
  const handleUpdateSuggestions = (suggestions) => {
    setRegNoSuggestions(suggestions);
  };
  const [query, setQuery] = useState(null);
  const debounceQuery = useDebounce(query, 1000);
  const formikRef = useRef();
  const [shouldApplyOnFocus, setShouldApplyOnFocus] = useState(false);
  const [
    getDataByRegNo,
    {
      data: regData,
      isFetching: isFetchingGetRegData,
      isLoading: isLoadingGetRegData,
      isSuccess: isSuccessGetRegData,
      isError: isErrorGetRegData,
    },
  ] = useLazyGetDataByRegNoQuery();

  useEffect(() => {
    console.log(debounceQuery);

    // if (debounceQuery && autoPopulate == 1) {
    if (autoPopulate == 1) {
      (async () => {
        try {
          getDataByRegNo({
            debounceQuery,
            course_id: formikRef?.current?.values?.course_id,
          });
        } catch (err) {
        } finally {
        }
      })();
    }
  }, [debounceQuery]);

  useEffect(() => {
    if (isSuccessGetRegData) {
      if (formikRef.current) {
        if (regData) {
          const previousValues = formikRef.current.values;
          const newValues = {
            ...previousValues,
            auto_populated: 1,
            name: regData.name || "",
            father_name: regData.father_name || "",
            exam_roll_no: regData.exam_roll_no || "",
            college_id: colleges.find((c) => c.code == regData.college_code)
              ?.id,
            degree_type_id: degreeTypes.find(
              (dc) => dc.name == regData.degree_type_name
            )?.id,
            degree_mode_id: degreeModes.find(
              (dm) => dm.name == regData.degree_mode_name
            )?.id,
            grade_id: grades.find((g) => g.name == regData.grade_name)?.id,
            // registration_no: regData.reg_no || "",
          };
          formikRef.current.setValues(newValues);
          console.log(degreeTypes);
        } else {
          toast.error(
            "Data not found. Please enter a valid registration number"
          );
          formikRef.current.setFieldValue("auto_populated", 0);
          formikRef.current.setFieldValue("name", "");
          formikRef.current.setFieldValue("father_name", "");
          formikRef.current.setFieldValue("exam_roll_no", "");
          formikRef.current.setFieldValue("college_id", "");
          formikRef.current.setFieldValue("degree_type_id", null);
          formikRef.current.setFieldValue("degree_mode_id", null);
          formikRef.current.setFieldValue("grade_id", null);
        }
      }
    }
  }, [regData, isLoadingGetRegData, isSuccessGetRegData, isErrorGetRegData]);

  const [isSaving, setSaving] = useState(false);
  const [currentInput, setCurrentInput] = useState("");
  const [inputName, setInputName] = useState("");
  const [addApplication] = useAddApplicationMutation();
  const [updateApplication] = useUpdateApplicationMutation();
  const [isKeyboardVisible, setKeyboardVisibility] = useState(false);
  const {
    data: courses = [],
    isLoading: isLoadingGetCourses,
    isSuccess: isSuccessGetCourses,
    isError: isErrorGetCourses,
  } = useGetCoursesQuery();
  const {
    data: colleges = [],
    isLoading: isLoadingGetColleges,
    isSuccess: isSuccessGetColleges,
    isError: isErrorGetColleges,
  } = useGetCollegesQuery();

  const {
    data: grades = [],
    isLoading: isLoadingGetGrades,
    isSuccess: isSuccessGetGrades,
    isError: isErrorGetGrades,
  } = useGetGradesQuery();

  const [
    getDegreeTypesByCourse,
    {
      data: degreeTypes = [],
      isFetching: isFetchingGetDegreeTypes,
      isLoading: isLoadingGetDegreeTypes,
      isSuccess: isSuccessGetDegreeTypes,
      isError: isErrorGetDegreeTypes,
    },
  ] = useLazyGetDegreeTypesByCourseQuery();

  const [
    getDegreeModesByCourse,
    {
      data: degreeModes = [],
      isFetching: isFetchingGetDegreeModes,
      isLoading: isLoadingGetDegreeModes,
      isSuccess: isSuccessGetDegreeModes,
      isError: isErrorGetDegreeModes,
    },
  ] = useLazyGetDegreeModesByCourseQuery();

  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    course_id: Yup.number().required("Please select a degree"),
    // subject_id: Yup.number().required("Please select a subject"),

    final_sem: Yup.lazy((value, options) => {
      const courseId = options.parent.course_id;
      const minDate = courseId == 40 ? "2024-03-02" : "2023-11-01";
      const maxDate = courseId == 40 ? "2025-02-01" : "2024-10-31";
      return Yup.date()
        .required("This field is required!")
        .min(minDate, "Please enter a valid date")
        .max(maxDate, "Please enter a valid date");
    }),

    date_of_passing: Yup.lazy((value, options) => {
      const courseId = options.parent.course_id;
      const minDate = courseId == 40 ? "2024-03-02" : "2023-11-01";
      const maxDate = courseId == 40 ? "2025-02-01" : "2024-10-31";
      return Yup.date()
        .required("Date of passing is required")
        .min(minDate, "Please enter a valid date")
        .max(maxDate, "Please enter a valid date");
    }),

    registration_no: Yup.string().required("Registration no. is required"),
    name: Yup.string().required("Candidate Name is required"),
    father_name: Yup.string().required("Father's name is required"),
    college_id: Yup.string().required(
      "College/Department/Institute is required"
    ),

    assamese_declaration: Yup.boolean(),
    assamese_name: Yup.string().when("assamese_declaration", {
      is: (assamese_declaration) => assamese_declaration === false,
      then: () => Yup.string().required("Name is required"),
    }),

    // re_assamese_name: Yup.string().when("assamese_declaration", {
    //   is: (assamese_declaration) => assamese_declaration === false,
    //   then: () =>
    //     Yup.string()
    //       .oneOf([Yup.ref("assamese_name"), null], "Name must match")
    //       .required("Confirm name is required"),
    // }),
  });
  const [initialValues, setInitialValues] = useState({
    auto_populated: 0,
    assamese_declaration: false,
    terms_declaration: false,
    stepone_declaration: false,
    certificate_id: certificate_id,
    course_id: "",
    final_sem: "",
    date_of_passing: "",
    registration_no: "",
    name: "",
    father_name: "",
    exam_roll_no: "",
    college_id: "",
    degree_type_id: null,
    degree_mode_id: null,
    grade_id: null,
    phd_notification_no: "",
    assamese_name: "",
    // re_assamese_name: "",
    step: 2,
  });

  useEffect(() => {
    data &&
      setInitialValues({
        auto_populated: data?.auto_populated || 0,
        course_id: data?.course_id || "",
        final_sem: data?.final_sem ?? "",
        date_of_passing: data?.date_of_passing ?? "",
        registration_no: data?.registration_no ?? "",
        name: data?.name ?? "",
        father_name: data?.father_name ?? "",
        exam_roll_no: data?.exam_roll_no ?? "",
        college_id: data?.college_id || "",
        degree_type_id: data?.degree_type_id || null,
        degree_mode_id: data?.degree_mode_id || null,
        grade_id: data?.grade_id || null,
        phd_notification_no: data?.phd_notification_no || "",
        assamese_name: data?.assamese_name ?? "",
        // re_assamese_name: data?.assamese_name ?? "",
        assamese_declaration: data?.assamese_declaration || false,
        stepone_declaration: data?.stepone_declaration || false,
      });

    // data?.registration_no && setQuery(data?.registration_no);

    data?.auto_populated && setAutoPopulate(data?.auto_populated);
    data?.course_id && getDegreeTypesByCourse(data?.course_id);
    data?.course_id && getDegreeModesByCourse(data?.course_id);
  }, [dispatch, data]);

  const handleSubmit = async (payload) => {
    setSaving(true);
    if (!data) {
      try {
        const result = await addApplication(payload).unwrap();
        if (result.status) {
          setTab(2);
          toast("Updated Successfully");
        } else {
          toast(result.message || "");
        }
      } catch (err) {
        toast(err?.data?.message || "");
        console.log("error ", err);
      } finally {
        setSaving(false);
      }
    } else {
      try {
        const result = await updateApplication({
          ...payload,
          id: data.id,
          step: 2,
        }).unwrap();
        if (result.status) {
          setTab(2);
          toast("Updated Successfully");
        } else {
        }
      } catch (err) {
        toast(err?.data?.message || "");
        console.log("error ", err);
      } finally {
        setSaving(false);
      }
    }
  };

  useEffect(() => {
    setShouldApplyOnFocus(true);
  }, []);
  return (
    <>
      {isSaving && <Loader />}
      <div className='grid items-center justify-center min-h-full grid-cols-1 bg-white'>
        <div className='w-full p-5 space-y-8 rounded-lg shadow-2xl '>
          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              setFieldValue,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
            }) => (
              <Form>
                <div className='grid gap-3 md:grid-cols-3 sm:grid-cols-1'>
                  <CustomSelect
                    value={values.course_id || ""}
                    options={courses}
                    name='course_id'
                    label='Degree applied for'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "course_id",
                        selectedOption?.id
                      );

                      setAutoPopulate(
                        courses.find((c) => c.id == selectedOption?.id)
                          .auto_populate
                      );

                      getDegreeTypesByCourse(selectedOption?.id);
                      getDegreeModesByCourse(selectedOption?.id);

                      formikRef.current.setFieldValue("registration_no", "");
                      formikRef.current.setFieldValue("name", "");
                      formikRef.current.setFieldValue("father_name", "");
                      formikRef.current.setFieldValue("exam_roll_no", "");
                      formikRef.current.setFieldValue("college_id", "");
                      formikRef.current.setFieldValue("degree_type_id", null);
                      formikRef.current.setFieldValue("degree_mode_id", null);
                      formikRef.current.setFieldValue("grade_id", null);
                    }}
                  />

                  <div>
                    <Input
                      type='month'
                      name='final_sem'
                      label='Final Semester/Year examination held in (Month/Year)'
                    />
                    <p className='text-red-500'>
                      Please enter from Gradesheet/Marksheet
                    </p>
                  </div>
                  <div>
                    <Input
                      type='date'
                      placeholder='Enter your dd/mm/yyyy'
                      name='date_of_passing'
                      label='Date of Passing'
                    />
                    <p className='text-red-500'>
                      Please enter from Gradesheet/Marksheet
                    </p>
                  </div>
                  <div>
                    {autoPopulate == true && (
                      <SearchableDropdown
                        value={values.registration_no || ""}
                        updateSuggestions={handleUpdateSuggestions}
                        course_id={formikRef?.current?.values?.course_id}
                        name='registration_no'
                        placeholder='Search...'
                        label='Registration no. provided by Dibrugarh University'
                        options={regNoSuggestions}
                        setSelectedOption={(selectedOption) => {
                          console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            `registration_no`,
                            selectedOption ?? ""
                          );
                          if (
                            !values.registration_no ||
                            selectedOption.length == 0
                          ) {
                            setQuery(selectedOption);
                          }
                        }}
                      />
                    )}
                    {autoPopulate == false && (
                      <SearchInput
                        value={values.registration_no || ""}
                        name='registration_no'
                        label='Registration no. provided by Dibrugarh University'
                        placeholder='Enter Registration no. provided by Dibrugarh University'
                        onChange={(e) => {
                          setFieldValue("registration_no", e.target.value);
                          setQuery(e.target.value);
                        }}
                      />
                    )}

                    {isFetchingGetRegData && "Fetching Data..."}

                    <Input type='hidden' name='degree_type_id' />
                    <Input type='hidden' name='degree_mode_id' />
                    <Input type='hidden' name='grade_id' />
                    <Input type='hidden' name='auto_populated' />
                  </div>

                  <div className='md:col-span-2'>
                    <Input
                      readOnly={autoPopulate}
                      name='name'
                      label={
                        <div>
                          Name of the Candidate{" "}
                          <span className='text-red-500'>
                            (The name must be the same as it appears in the
                            Dibrugarh University Registration Card)
                          </span>
                        </div>
                      }
                      placeholder='Enter Name of the Candidate'
                    />
                  </div>
                  <div className='grid grid-cols-2 gap-4 md:col-span-3'>
                    <AssameseInput
                      name='assamese_name'
                      label='Name of the Candidate in Assamese'
                      placeholder='Enter Name of the Candidate in Assamese'
                      // onChange={setFieldValue}
                      value={values.assamese_name || ""}
                      onFocus={(e) => {
                        if (shouldApplyOnFocus) {
                          if (!values.assamese_declaration) {
                            setInputName("assamese_name");
                            setKeyboardVisibility(true);
                          }
                        }
                      }}
                      readOnly={values.assamese_declaration}
                    />

                    {/* <AssameseInput
                      name="re_assamese_name"
                      label="Re-enter Name of the Candidate in Assamese"
                      placeholder="Enter Name of the Candidate in Assamese"
                      // onChange={setFieldValue}
                      value={values.re_assamese_name || ""}
                      onFocus={(e) => {
                        if (shouldApplyOnFocus) {
                          if (!values.assamese_declaration) {
                            setInputName("re_assamese_name");
                            setKeyboardVisibility(true);
                          }
                        }
                      }}
                      readOnly={values.assamese_declaration}
                    /> */}
                    <div className='w-full col-span-2 ml-auto'>
                      {isKeyboardVisible && (
                        <AssameseKeyboard
                          name={inputName}
                          value={values}
                          setFieldValue={setFieldValue}
                          onClose={() => {
                            setKeyboardVisibility(false);
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div className='md:col-span-3'>
                    <input
                      type='checkbox'
                      id='assamese_checked'
                      name='assamese_declaration'
                      checked={values?.assamese_declaration}
                      className='text-green-600 border-gray-300 rounded shadow-sm focus:border-green-600 focus:ring focus:ring-green-200 focus:ring-opacity-50'
                      onChange={(e) => {
                        setFieldValue("assamese_declaration", e.target.checked);
                        if (e.target.checked) {
                          setFieldValue("assamese_name", "");
                          setFieldValue("re_assamese_name", "");
                        }
                        setKeyboardVisibility(false);
                      }}
                    />
                    <label
                      htmlFor='assamese_checked'
                      className='ml-2 text-sm text-gray-700'
                    >
                      <i className='text-red-500'>
                        I don't know how to write my name in Assamese.
                      </i>{" "}
                      Therefore, I authorize the Certificate Branch, DU to
                      convert my name to Assamese on my behalf. I will have No
                      Objection on the spelling of my name in Assamese converted
                      by the Certificate Branch, DU.
                    </label>
                  </div>

                  <Input
                    readOnly={regData?.father_name}
                    name='father_name'
                    label="Father's Name"
                    placeholder="Enter Your Father's Name"
                  />

                  <CustomSelect
                    readOnly={autoPopulate}
                    value={values.college_id || ""}
                    options={colleges}
                    name='college_id'
                    label='College/Department/Institute from where qualified'
                    isSearchable={autoPopulate ? false : true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "college_id",
                        selectedOption.id
                      );
                    }}
                  />
                  {values.course_id === 40 && (
                    <CustomSelect
                      value={values.phd_notification_no || ""}
                      options={[
                        {
                          id: "Notification No. DU/RG/Ph. D./10/2024/7216 dated 04/11/2024",
                          name: "Notification No. DU/RG/Ph. D./10/2024/7216 dated 04/11/2024",
                        },
                        {
                          id: "Notification No. DU/RG/Ph. D./09/2024/7159 dated 01/10/2024",
                          name: "Notification No. DU/RG/Ph. D./09/2024/7159 dated 01/10/2024",
                        },
                        {
                          id: "Notification No. DU/RG/Ph. D./08/2024/7108 dated 02/09/2024",
                          name: "Notification No. DU/RG/Ph. D./08/2024/7108 dated 02/09/2024",
                        },
                        {
                          id: "Notification No. DU/RG/Ph. D./07/2024/7033 dated 01/08/2024",
                          name: "Notification No. DU/RG/Ph. D./07/2024/7033 dated 01/08/2024",
                        },
                        {
                          id: "Notification No. DU/RG/Ph. D./06/2024/6960 dated 01/07/2024",
                          name: "Notification No. DU/RG/Ph. D./06/2024/6960 dated 01/07/2024",
                        },
                        {
                          id: "Notification No. DU/RG/Ph. D./05/2024/6881 dated 03/06/2024",
                          name: "Notification No. DU/RG/Ph. D./05/2024/6881 dated 03/06/2024",
                        },
                        {
                          id: "Notification No. DU/RG/Ph. D./05/2024/6798 dated 02/05/2024",
                          name: "Notification No. DU/RG/Ph. D./05/2024/6798 dated 02/05/2024",
                        },
                        {
                          id: "Notification No. DU/RG/Ph. D./04/2024/6732 dated 01/04/2024",
                          name: "Notification No. DU/RG/Ph. D./04/2024/6732 dated 01/04/2024",
                        },
                      ]}
                      name='phd_notification_no'
                      label='Select Notification No.'
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option.name} `}
                      onChange={(selectedOption) => {
                        formikRef.current?.setFieldValue(
                          "phd_notification_no",
                          selectedOption.id
                        );
                      }}
                    />
                  )}
                  <Input type='hidden' name='exam_roll_no' />

                  <div className='md:col-span-3'>
                    <input
                      type='checkbox'
                      id='declarationCheckbox'
                      name='stepone_declaration'
                      checked={values?.stepone_declaration}
                      className='text-green-600 border-gray-300 rounded shadow-sm focus:border-green-600 focus:ring focus:ring-green-200 focus:ring-opacity-50'
                      onChange={(e) => {
                        setFieldValue("stepone_declaration", e.target.checked);
                      }}
                    />
                    <label
                      htmlFor='declarationCheckbox'
                      className='ml-2 text-sm text-gray-700'
                    >
                      I declare that the information provided in this form is
                      true and correct to the best of my knowledge and belief.
                    </label>
                  </div>
                  <hr />
                  <div className='flex justify-end md:col-span-3'>
                    <button
                      disabled={isSaving || !values.stepone_declaration}
                      className={`${
                        values.stepone_declaration
                          ? "bg-blue-500"
                          : "bg-blue-300"
                      } text-white py-2 px-4 rounded`}
                      type='submit'
                      // onClick={handleSubmit}
                    >
                      {isSaving ? "Saving..." : "Next"}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CertificateCreateStep1;

// const handleScreenClick = (e) => {
//   e.preventDefault();
//   if (isAssameseKeyboardVisible) {
//     setAssameseKeyboardVisibility(false);
//   }
// };
// const targetDivRef = useRef();
// useEffect(() => {
//   document.addEventListener("click", handleClickOutside);
//   return () => {
//     document.removeEventListener("click", handleClickOutside);
//   };
// }, []);
// const handleClickOutside = (event) => {
//   // console.log('targetDivRef',targetDivRef.current)
//   // console.log('event.target',event.target)
//   // if (targetDivRef.current && !targetDivRef.current.contains(event.target)) {
//   //   setAssameseKeyboardVisibility(false);
//   // }
// };
